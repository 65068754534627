import { createSlice } from '@reduxjs/toolkit'

export const currentOrdersSlice = createSlice({
    name: 'currentOrders',
    initialState: {
        data: {},
        prevLineTotalExclTax: 0,
    },
    reducers: {
        addOrder: (state, action) => {
            state.data = action.payload
        },
        updatePrevLineTotal: (state, action) => {
            state.prevLineTotalExclTax = action.payload
        },
        removeOrderByIndex: (state, action) => {
            const index = action.payload
            if (index >= 0 && index < state.orders.length) {
                state.orders.splice(index, 1)
            }
        },
        updateOrderByIndex: (state, action) => {
            const { index, updatedOrder } = action.payload
            if (index >= 0 && index < state.orders.length) {
                state.orders[index] = updatedOrder
            }
        },
        clearOrders: (state) => {
            state.data = {}
            state.prevLineTotalExclTax = 0
        },
    },
})

export const {
    addOrder,
    removeOrderByIndex,
    updatePrevLineTotal,
    updateOrderByIndex,
    clearOrders,
} = currentOrdersSlice.actions

export const selectOrders = (state) => state.currentOrders.data
export const selectPrevLineTotalExclTax = (state) =>
    state.currentOrders.prevLineTotalExclTax

export default currentOrdersSlice.reducer
