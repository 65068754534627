const appConfig = {
  apiBaseUrl: process.env.REACT_APP_BASE_URL,
  apiPrefix: "/api",
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
};

export default appConfig;
