import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'

export const fetchAddresses = createAsyncThunk('addresses/fetch', async () => {
    try {
        const response = await BaseService.get(
            `/campaign/order/getCustomerAddresses?addressTypes=isDelivery,isAccounting`
        )
        return response.data
    } catch (error) {
        throw new Error('Failed to fetch addresses data')
    }
})
const initialState = {
    data: {},
    isLoading: false,
    error: null,
    selectedAddresses: {
        delivery: null,
        accounting: null,
    },
    selectedAddressesData: {
        delivery: null,
        accounting: null,
    },
}

const addressesSlice = createSlice({
    name: 'addresses',
    initialState,
    reducers: {
        updateDeliveryAddress: (state, action) => {
            state.selectedAddresses.delivery = action.payload
            state.selectedAddressesData.delivery =
                state?.data?.isDelivery?.find(
                    (item) => item?._id === action.payload
                )
        },
        updateAccountingAddress: (state, action) => {
            state.selectedAddresses.accounting = action.payload
            state.selectedAddressesData.accounting =
                state.data?.isAccounting?.find(
                    (item) => item?._id === action.payload
                )
        },
        resetAddresses: (state, action) => {
            state.data = {}
            state.isLoading = false
            state.error = null
            state.selectedAddresses = {
                delivery: null,
                accounting: null,
            }
            state.selectedAddressesData = {
                delivery: null,
                accounting: null,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAddresses.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchAddresses.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload?.result
            })
            .addCase(fetchAddresses.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

export const selectData = (state) => state.addresses.data
export const selectIsLoading = (state) => state.addresses.isLoading
export const selectError = (state) => state.addresses.error
export const selectedAddresses = (state) => state.addresses.selectedAddresses
export const selectedAddressData = (state) =>
    state.addresses.selectedAddressesData
export const {
    updateAccountingAddress,
    updateDeliveryAddress,
    resetAddresses,
} = addressesSlice.actions

export default addressesSlice.reducer
