import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from 'services/BaseService'

export const fetchFacets = createAsyncThunk('facets/fetch', async (query) => {
    const id = query?.id

    try {
        const response = await BaseService.get(`/campaign/catalog/facets/${id}`)
        return response.data
    } catch (error) {
        throw new Error('Failed to fetch facets data')
    }
})

// Redux Toolkit slice
const facetsSlice = createSlice({
    name: 'facets',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        filters: [],
    },
    reducers: {
        resetFacets: (state) => {
            state.data = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacets.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchFacets.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchFacets.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})

export const selectData = (state) => state.facets.data
export const selectIsLoading = (state) => state.facets.isLoading
export const selectError = (state) => state.facets.error
export const { resetFacets } = facetsSlice.actions
export default facetsSlice.reducer
